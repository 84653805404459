import React, {FC, memo} from 'react';
import {IListProps} from '@common/types';
import {DefaultList} from '@components/PageStep/StepChildren/ExclusiveStepComponents/List/DefaultList';
import {CustomList} from '@components/PageStep/StepChildren/ExclusiveStepComponents/List/CustomList';


const List: FC<IListProps> = ({items, height, position, type}) => {
  return !items?.some(item => item?.tags?.length) ? (
    <DefaultList position={position} type={type} height={height || null} items={items}/>
  ) : (
    <CustomList position={position} type={type} height={height || null} items={items}/>
  )
}

export default memo(List)
