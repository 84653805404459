import React, {FC, memo} from 'react';
import {IGridProps} from '@common/types';
import ToolsGrid from '@common/components/PageStep/StepGrids/GridVariants/ToolsGrid';
import SimpleGrid from '@common/components/PageStep/StepGrids/GridVariants/SimpleGrid';
import {EBlockType, EComponent} from '@redux/types';
import ProductsGrid from '@common/components/PageStep/StepGrids/GridVariants/ProductsGrid';
import StackGrid from '@common/components/PageStep/StepGrids/GridVariants/StackGrid';


/**
 * Компонент для отрисовки разного рода "гридов" - списков простых карточек, с текстом и картинками
 * Применяются для компонента "Стек", "Список карточек". Для карточек в шаге типа inner-tool используется отдельная верстка
 * @param props - список карточек, высота карточки, тип шага, родительский шаг, тип компонента карточек
 * @constructor
 */
const Index: FC<IGridProps> = (props) => {
  const {items, height, type, parent, position, componentType} = props;


  if (parent?.type === EBlockType.tools) {
    return (
      <ToolsGrid items={items} position={position || null}/>
    )
  }

  if (componentType === EComponent.STACK_LIST) {
    return (
      <StackGrid items={items}/>
    )
  }

  const isCardImage = items?.some(i => !!i.image?.url);

  return isCardImage ? (
    <ProductsGrid items={items} type={type || null} position={position || null}/>
  ) : (
    <SimpleGrid items={items} height={height || null} type={type || null} position={position || null} parent={parent || null}/>
  )
}

export default memo(Index)
