import React, {FC, ReactNode} from 'react';
import {EBlockPosition, IStepComponent, TComponent} from '@redux/types';
import {useStepHeaderSettings} from '@common/hooks/useStepHeaderSettings';
import Text from '@common/components/common/Text';
import RichTextContent from '@common/components/RichTextContent';
import StepChildren from '@common/components/PageStep/StepChildren';


export const StepTableHeader: FC<{ step: IStepComponent, stepChildren: TComponent[], children?: ReactNode }> = (
  {step, stepChildren, children}) => {

  const {emptyText, stepDescriptionInsideStepCount, headerClasses} = useStepHeaderSettings({
    step, stepChildren, children,
    classes: {
      mainClass: 'inner-table__title',
      marginClass: 'mb-40 mb-sm-40',
      mobileSmallTitleClass: 'inner-table__title--small'
    }
  });

  return (
    <>
      {step?.title && (
        <h2 className={headerClasses}>
          <Text text={step.title}/>
        </h2>
      )}
      {!emptyText && (
        <div className={"inner-grid__content mb-65 mb-md-40 mb-sm-40"}>
          <RichTextContent content={step.description} parent={step}/>

          {!stepDescriptionInsideStepCount ? children : stepChildren?.map(stepChild => {
            if (stepChild?.placement?.position !== EBlockPosition.insideStepDescription) {
              return null;
            }
            return (
              <StepChildren key={stepChild?.id} components={[stepChild]} parentStep={step}/>
            )
          })}
        </div>
      )}
    </>
  )
}
