import React, {FC, memo, useMemo} from 'react';
import {IGridProps} from '@common/types';
import Link from 'next/link';
import Picture from '@common/components/common/Picture';
import {EBlockPosition, EComponent} from '@redux/types';
import RichTextContent from '@common/components/RichTextContent';
import Text from '@common/components/common/Text';


const ProductsGrid: FC<IGridProps> = ({items, position}) => {

  const isLink = useMemo(() => items.some(i => !!i.url), [items])

  const productGridClasses = useMemo(() => {
    const marginTopClassesByPosition = {
      [EBlockPosition.insideStepDescription]: 'mt-20 mt-md-20 mt-sm-10',
      [EBlockPosition.insideStep]: 'mt-40 mt-md-40 mt-sm-20',
      [EBlockPosition.outsideStep]: '',
    }[position || EBlockPosition.outsideStep];

    return ['products__list', marginTopClassesByPosition].join(' ');
  }, [position])

  return (
    <ul className={productGridClasses}>
      {items?.map((c, index) => {
        const cardTypeLink = c.type === 'rounded-right' ? 'products__link--radius-right' :
          c.type === 'rounded' ? 'products__link--radius-all' : '';
        const classes = ['products__link', cardTypeLink].join(' ');

        return (
          <li className={'products__item'} key={`card_${c.id}_${index}`}>
            <Link className={classes} href={c.url ?? '#main-products'}>
              <div className="products__picture">
                {c?.image && (
                  <Picture media={{__component: EComponent.MEDIA, image: c.image}} withoutWrapper={isLink}/>
                )}
              </div>
              <h3 className={'products__title'}>
                <Text text={c.title}/>
              </h3>
              {c?.text && (
                <div className={'products__description'}>
                  <RichTextContent content={c?.text}/>
                </div>
              )}
            </Link>
          </li>
        )
      })}
    </ul>
  )
}

export default memo(ProductsGrid)
