import React, {FC, useMemo} from 'react';
import {EPage, IListProps} from '@common/types';
import {useTypedSelector} from '@common/hooks/useTypedSelector';
import {EBlockPosition, EBlockType} from '@redux/types';
import RichTextContent from '@components/RichTextContent';
import Text from '@components/common/Text';

export const DefaultList: FC<IListProps> = ({position, type, height, items}) => {
  const {page} = useTypedSelector(state => state.app)

  const defaultListClasses = useMemo(() => {
    const marginClassByTypeForDefaultList = {
      [EBlockType.sticky]: 'inner-simple-table--margin-top-small inner-simple-table--margin-10-10-5',
      [EBlockType.simple]: 'inner-simple-table--margin-top-big inner-simple-table--margin-25-25-5',
      [EBlockType.grid]: 'inner-simple-table--margin-top-middle inner-simple-table--margin-25-25-5',
      [EBlockType.table]: 'inner-simple-table--margin-top-middle inner-simple-table--margin-10-10-5',
      [EBlockType.tools]: 'inner-simple-table--margin-top-middle inner-simple-table--margin-10-10-5',
    }[type || EBlockType.grid];

    const marginClasses =  position === EBlockPosition.outsideStep && height !== 'small' ?
      'inner-simple-table--margin-top-big inner-simple-table--tablet-font-middle inner-simple-table--margin-10-10-5' :
      marginClassByTypeForDefaultList;
    const gapClass = height === 'small' || !height ? '' : 'inner-simple-table--gap-20-0-0';
    const fontClass = height === 'big' || !height ? 'inner-simple-table--tablet-font-middle' : '';

    return [
      'inner-simple-table inner-simple-table--no-padding ', gapClass, `inner-simple-table--height-small`, fontClass,
      marginClasses
    ].join(' ')
  }, [type, position, height]);

  return (
    <div className={defaultListClasses}>
      <ul className="inner-simple-table__list">
        {items?.map((l, index) => {
          const title = l.title;
          const customTitle = l.customTitle;
          const itemClasses = [
            'inner-simple-table__item',
            page !== EPage.SOLUTION ? '' : 'inner-simple-table__item--no-border'
          ].join(' ');

          return (
            <li key={`list_item_${l.title}_${index}`} className={itemClasses}>
            <span className="inner-simple-table__key">
              {title ? <Text text={title}/> : <RichTextContent classes="mt-0" content={customTitle}/>}
            </span>
              <div className="inner-simple-table__value">
                <RichTextContent content={l?.text}/>
              </div>
            </li>
          )
        })}
      </ul>
    </div>
  )
}
