import React, {FC, useMemo} from 'react';
import {IListProps} from '@common/types';
import {EBlockPosition, EBlockType} from '@redux/types';
import Text from '@components/common/Text';
import RichTextContent from '@components/RichTextContent';
import Tags from '@components/Tags';


export const CustomList: FC<IListProps> = ({position, type, height, items}) => {
  const customClasses = useMemo(() => {
    const marginClassByTypeForInsideStep = {
      [EBlockType.sticky]: 'custom-table--margin-top-small',
      [EBlockType.simple]: 'custom-table--margin-top-large',
      [EBlockType.grid]: 'custom-table--margin-top-big',
      [EBlockType.table]: 'custom-table--margin-top-big',
      [EBlockType.tools]: 'custom-table--margin-top-big',
    }[type || EBlockType.grid];

    const classesByPosition = {
      [EBlockPosition.insideStepDescription]: ' custom-table--margin-top-small',
      [EBlockPosition.insideStep]: marginClassByTypeForInsideStep,
      [EBlockPosition.outsideStep]: '',
    }[position || EBlockPosition.outsideStep];

    const gapClass = height === 'small' || !height ? 'custom-table--gap-0-5' : 'custom-table--gap-25-20';
    const columnClass = position === EBlockPosition.insideStepDescription ? 'custom-table--column-ratio-1-3' :
      'custom-table--column-ratio-1-2';

    return [
      'custom-table', classesByPosition, gapClass, columnClass
    ].join(' ')
  }, [position, height, type]);

  return (
    <ul className={customClasses}>
      {items?.map((l, index) => {
        const title = l.title;
        const customTitle = l.customTitle;
        const key = `list_item_${l.title}_${index}`
        const textClasses = height === 'small' ? 'inner-simple-text inner-simple-text--font-small' : 'inner-simple-text';

        return (
          <li key={key} className="custom-table-item">
            <div className="custom-table-item__key">
              <p>
                {title ? <Text text={title}/> : <RichTextContent classes="mt-0" content={customTitle}/>}
              </p>
            </div>
            <div className="custom-table-item__value">
              {l?.text && (
                <div className={textClasses}>
                  <RichTextContent content={l?.text}/>
                </div>
              )}
              {!!l?.tags?.length && (
                <Tags tags={l.tags}/>
              )}
            </div>
          </li>
        )
      })}
    </ul>
  )
}
