import React, {FC, ReactNode} from 'react';
import {EBlockPosition, EComponent, IStepComponent, TComponent} from '@redux/types';
import {useStepHeaderSettings} from '@common/hooks/useStepHeaderSettings';
import RichTextContent from '@common/components/RichTextContent';
import Text from '@common/components/common/Text';
import StepChildren from '@common/components/PageStep/StepChildren';


export const StepSimpleBlockHeader: FC<{ step: IStepComponent, stepChildren: TComponent[], children?: ReactNode }> = (
  {step, stepChildren, children}) => {

  const {emptyText, emptyNote, stepDescriptionInsideStepCount, headerClasses} = useStepHeaderSettings({
    step, stepChildren, children,
    classes: {
      mainClass: 'inner-simple-title',
      emptyMarginClass: 'inner-simple-title--no-margin mt-65 mt-md-40',
      mobileNormalTitleClass: 'inner-simple-title--font-40-40-40 inner-simple-title--mobile-margin-middle'
    }
  });

  if (!(step?.title || !emptyText || stepDescriptionInsideStepCount || children)) {
    return null
  }

  return (
    <div className="inner-simple-block__content">
      {!emptyNote && (
        <div
          className="aside inner-simple-block__aside--mobile-bottom inner-simple-block__aside inner-simple-block__aside--margin-big">
          <div className="aside__text">
            <RichTextContent content={step?.note}/>
          </div>
        </div>
      )}

      {(step?.title || !emptyText || stepDescriptionInsideStepCount || children) && (
        <div className="inner-simple-block__description">
          {step?.title && (
            <h2 className={headerClasses}>
              <Text text={step.title}/>
            </h2>
          )}
          {!emptyText && (
            <span className="inner-simple-block__description">
              <RichTextContent content={step.description} parent={step}/>
            </span>
          )}

          {!stepDescriptionInsideStepCount ? children : stepChildren?.map(stepChild => {
            if (stepChild?.placement?.position !== EBlockPosition.insideStepDescription) {
              return null;
            }
            return (
              stepChild?.__component !== EComponent?.PARAGRAPH ? (
                <StepChildren key={stepChild?.id} components={[stepChild]} parentStep={step}/>
              ) : (
                <span className="inner-simple-block__description">
                  <StepChildren key={stepChild?.id} components={[stepChild]} parentStep={step}/>
                </span>
              )
            )
          })}
        </div>
      )}
    </div>
  )
}
