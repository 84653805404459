import React, {FC} from 'react';

const Tags: FC<{ tags?: string[], classNames?: string }> = ({
                                                              tags,
                                                              classNames = 'inner-styled-list--bg-snow inner-styled-list--margin-top-small'
}) => {

  return (!!tags?.length && (
    <ul className={`inner-styled-list ${classNames}`}>
      {tags?.map((tag) => (
        <li key={tag} className="inner-styled-list__item">
          <p>{tag}</p>
        </li>
      ))}
    </ul>
  ))
}

export default Tags
