import React, {FC, memo, useMemo} from 'react';
import {IBannerProps} from '@common/types';
import Picture from '@common/components/common/Picture';
import {EBlockPosition, EBlockType, EComponent} from '@redux/types';
import RichTextContent from '@common/components/RichTextContent';
import Text from '@common/components/common/Text';
import {isNotEmptyRichContent} from '@common/functions';


const Banner: FC<IBannerProps> = ({banner}) => {

  const emptyText = useMemo(() => !isNotEmptyRichContent(banner?.text), [banner]);

  if (banner.type === 'block-picture') {
    return (
      <div className="picture-text">
        <div className="picture-text__wrapper">
          <div className="inner-picture picture-text__picture">
            <Picture
              media={{
                image: banner.backgroundImage.data?.attributes || null,
                imageMobile: banner.imageMobile?.data?.attributes || null,
                __component: EComponent.MEDIA
              }}
              withoutWrapper={true}
            />
          </div>
          {(!emptyText || banner?.title) && (
            <div className="picture-text__text">
              <h2 className="picture-text__title"><Text text={banner?.title}/></h2>
              <RichTextContent content={banner?.text} classes={"picture-text__texts"}/>
            </div>
          )}
        </div>
        <p className="picture-text__description">
          <Text text={banner.backgroundImage.data?.attributes?.caption}/></p>
      </div>
    )
  }

  const textClasses = [
    'inner-simple-text',
    (!emptyText ? 'inner-simple-text--font-40-20-20' : 'inner-simple-text--big inner-simple-text--font-40-35-20')
  ].join(' ');

  const isOutsideBlock = banner?.placement?.position === EBlockPosition.outsideStep && banner.placement?.type !== EBlockType.sticky
  const marginClassesByPosition = !isOutsideBlock ? '' : 'inner-image-text--no-margin';

  const marginClassesByBannerType = banner?.type !== 'block-10' ? '' : 'inner-image-text--grid inner-image-text--margin-top-40';

  const containerClasses = ['inner-image-text', marginClassesByBannerType, marginClassesByPosition,
    `inner-image-text--${banner.type ?? 'block-10'}`].join(' ');

  const smallTextClasses = [
    'inner-simple-text',
    'inner-simple-text--small',
    'inner-simple-text--margin-top-40-20',
    'inner-simple-text--font-20-15-15'
  ].join(' ');

  return (
    <div className={containerClasses}>
      <div className="inner-image-text__content">
        <div className="inner-image-text__image">
          <Picture
            media={{
              image: banner.backgroundImage.data?.attributes || null,
              imageMobile: banner.imageMobile?.data?.attributes || null,
              __component: EComponent.MEDIA
            }}
            withoutWrapper={true}
          />
        </div>
        <div className="inner-image-text__description">
          <div className={textClasses}>
            <p><Text text={banner?.title}/></p>
          </div>
          {!emptyText && (
            <div className={smallTextClasses}>
              <RichTextContent content={banner?.text}/>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default memo(Banner)
