import React, {FC} from 'react';
import {IVacancyListItemProps} from '@redux/types';
import Link from 'next/link';
import Text from '@common/components/common/Text';


const VacancyList: FC<IVacancyListItemProps> = ({items}) => {
  return (
    <ul className="inner-simple-cards inner-simple-cards--half">
      {items?.map((vac, index) => (
        <li key={`simple-card-${vac?.slug}-${index}`} className="inner-simple-cards__item">
          <Link
            className={`inner-simple-cards__card-link ${vac.meta?.onlyPreview ? 'only-preview' : ''}`}
            href={vac?.slug && !vac.meta?.onlyPreview ? vac.slug : ''}
          >
            <h3 className="inner-simple-cards__heading"><Text text={vac.title}/></h3>
          </Link>
        </li>
      ))}
    </ul>
  )
}

export default VacancyList
