import React, {FC, memo} from 'react';
import {IQuoteProps} from '@common/types';
import {EBlockPosition, EBlockType} from '@redux/types';
import RichTextContent from '@components/RichTextContent';
import {QuoteMedia} from '@components/PageStep/StepChildren/ExclusiveStepComponents/Quote/QuoteMedia';


const Quote: FC<IQuoteProps> = ({quote}) => {

  let cardTextClasses = ['inner-sticky-block__card', `inner-sticky-block__card--${quote.color ?? 'black'}`,
    `inner-sticky-block__card--${quote.height ?? 'mh-default'}`].join(' ');

  const marginForSticky = quote?.placement?.type === EBlockType.sticky ? '' : 'inner-sticky-block__content--no-margin';
  const marginForSimple = quote?.placement?.type === EBlockType.simple ? 'inner-sticky-block__content--margin-big' : '';
  const marginClasses = quote?.placement?.position === EBlockPosition.outsideStep ? marginForSticky : marginForSimple;

  let wrapperClassNames = ['inner-sticky-block__content', marginClasses].join(' ');

  return (
    <div className={wrapperClassNames}>
      <h2 className="inner-sticky-block__title inner-sticky-block__title--hidden">Заголовок</h2>
      <div className="inner-sticky-block__aside aside">
        <QuoteMedia quote={quote} isReviewPlay={true}/>
      </div>
      <div className={cardTextClasses}>
        <div className="inner-sticky-block__text">
          <RichTextContent content={quote?.text}/>
        </div>
        <div className="inner-sticky-block__author">
          <QuoteMedia quote={quote} isReviewPlay={false}/>
          <div className="inner-sticky-block__author-info">
            <p>{quote.person?.fullName}</p>
            <p>{quote.person?.position}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default memo(Quote)


