import React, {FC, memo, useMemo} from 'react';
import {IGridProps} from '@common/types';
import Picture from '@common/components/common/Picture';
import {EBlockPosition, EComponent} from '@redux/types';
import RichTextContent from '@common/components/RichTextContent';
import Text from '@common/components/common/Text';


const ToolsGrid: FC<IGridProps> = ({items, position}) => {

  const toolsGridClasses = useMemo(() => {
    const twoItemsClasses = items?.length > 2 ? '' :
      `inner-tools--2-items${position === EBlockPosition.insideStepDescription ? '' : ' two-part'}`;

    const positionClasses = {
      [EBlockPosition.insideStepDescription]: 'mt-20 mt-md-20 mt-sm-10',
      [EBlockPosition.insideStep]: 'mt-40 mt-md-40 mt-sm-20',
      [EBlockPosition.outsideStep]: '',
    }[position || EBlockPosition.outsideStep];

    return ['inner-tools__list', twoItemsClasses, positionClasses].join(' ');
  }, [position]);

  return (
    <ul className={toolsGridClasses}>
      {items?.map((c, index) => {
        const cardType = c.type === 'rounded-right' ? 'inner-tools__item--border-right' : c.type === 'rounded' ? 'inner-tools__item--border-round' : ''
        const Card = () => (
          <>
            {c?.image && <Picture media={{__component: EComponent.MEDIA, image: c.image}}/>}
            <h3 className={'inner-tools__heading'}>
              <Text text={c.title}/>
            </h3>
            {c?.text && (
              <div className={'inner-tools__description'}>
                <RichTextContent content={c?.text}/>
              </div>
            )}
          </>
        )
        return (
          <li className={['inner-tools__item', cardType].join(' ')} key={`card_${c.id}_${index}`}>
            <Card/>
          </li>
        )
      })}
    </ul>
  )
}

export default memo(ToolsGrid)
