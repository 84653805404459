import {EComponent, IQuoteComponent} from '@redux/types';
import {useActions} from '@common/hooks/useActions';
import Picture from '@components/common/Picture';
import Icon from '@components/common/Icon';
import Text from '@components/common/Text';
import React from 'react';

export const QuoteMedia = ({quote, isReviewPlay}: {quote: IQuoteComponent, isReviewPlay: boolean}) => {
  const actions = useActions()

  const video = quote?.person?.video?.data?.attributes;
  const image = quote?.person?.image?.data?.attributes || null;

  return (
    <>
      {video ? (
        <div className="inner-sticky-block__video">
          <div className="inner-sticky-block__video-picture">
            <Picture media={{__component: EComponent.MEDIA, image: image}} withoutWrapper={true}/>
          </div>
          {isReviewPlay ? (
            <button
              className="review__play"
              onClick={() => actions.setApp({modalVideo: {video, open: true}})}
              data-review-video-link="v-3/video/video-review-geoscan.mp4"
            >
              <Icon name="icon-play" className="review__play-icon" width={50} height={58}/>
            </button>
          ) : (
            <button
              className="inner-sticky-block__video-play"
              onClick={() => actions.setApp({modalVideo: {video, open: true}})}
              data-review-video-link="v-3/video/video-review-geoscan.mp4"
            >
              <Icon name="icon-play" className="inner-sticky-block__video-icon" width={18} height={18}/>
            </button>
          )}

          <div className="inner-sticky-block__video-author">
            {!!quote.person?.fullName && (
              <span className="inner-sticky-block__video-name">{quote.person?.fullName}</span>
            )}
            {!!video.caption && (
              <span className="inner-sticky-block__video-project"><Text text={video.caption}/></span>
            )}
          </div>
        </div>
      ) : (
        <>
          {!!image && (
            <Picture media={{
              __component: EComponent.MEDIA,
              image: image,
              imageMobile: quote.person.imageMobile?.data?.attributes || null
            }} wrapperClassName={'inner-sticky-block__picture'}/>
          )}
        </>
      )}
    </>
  )
}
