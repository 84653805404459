import React, {FC, ReactNode} from 'react';
import {EBlockPosition, IStepComponent, TComponent} from '@redux/types';
import {useStepHeaderSettings} from '@common/hooks/useStepHeaderSettings';
import RichTextContent from '@common/components/RichTextContent';
import Text from '@common/components/common/Text';
import StepChildren from '@common/components/PageStep/StepChildren';


export const StepGridHeader: FC<{ step: IStepComponent, stepChildren: TComponent[], children?: ReactNode }> = (
  {step, stepChildren, children}) => {

  const {emptyText, emptyNote, stepDescriptionInsideStepCount, headerClasses} = useStepHeaderSettings({
    step, stepChildren, children,
    classes: {
      mainClass: 'inner-simple-title',
      emptyMarginClass: 'inner-simple-title--no-margin mt-65 mt-md-40',
      mobileNormalTitleClass: 'inner-simple-title--font-40-40-40 inner-simple-title--mobile-margin-middle'
    }
  });

  if (!(step?.title || !emptyText || stepDescriptionInsideStepCount || children)) {
    return null
  }

  return (
    <>
      {!emptyNote && (
        <div className="inner-grid__aside inner-grid__aside--mobile-top">
          <div className="inner-simple-text inner-simple-text--mobile-margin-big">
            <RichTextContent content={step?.note}/>
          </div>
        </div>
      )}

      <div className="inner-grid__content">
        {step?.title && (
          <h2 className={headerClasses}>
            <Text text={step.title}/>
          </h2>
        )}
        {!emptyText && (
          <RichTextContent content={step.description} parent={step}/>
        )}
        {!stepDescriptionInsideStepCount ? children : stepChildren?.map(stepChild => {
          if (stepChild?.placement?.position !== EBlockPosition.insideStepDescription) {
            return null;
          }
          return (
            <StepChildren key={stepChild?.id} components={[stepChild]} parentStep={step}/>
          )
        })}
      </div>
    </>
  )
}
