import React, {FC, useEffect, useMemo, useState} from 'react';
import {EBlockPosition, EBlockType, EEmbedMediaKeys, IEmbedVideo} from '@redux/types';
import Text from '@common/components/common/Text';


const EmbedMedia: FC<{
  embed?: IEmbedVideo | undefined,
  type?: EBlockType, position?: EBlockPosition
}> = ({embed, type = EBlockType?.sticky, position = EBlockPosition.insideStep}) => {
  const [videoUrl, setVideoUrl] = useState<string | null>('');
  const [videoTitle, setVideoTitle] = useState('');

  useEffect(() => {
    if (embed?.video) {
      setVideoUrl(getId(embed?.video));
    }
  }, [embed])

  const getId = (embedMedia: string | undefined) => {
    if (embedMedia) {
      const embedObj = JSON?.parse(embedMedia || '');
      setVideoTitle(embedObj?.title);
      if (embedObj?.mime === EEmbedMediaKeys.youtube) {
        const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
        const match = embedObj?.url.match(regExp);

        return (match && match[2].length === 11)
          ? `//www.youtube.com/embed/${match[2]}`
          : null;
      }

      if (embedObj?.mime === EEmbedMediaKeys.vimeo) {
        const regExp = /^(http|https)?:\/\/(www\.|player\.)?vimeo\.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|video\/|)(\d+)(?:|\/\?)$/;
        const match = embedObj?.url.match(regExp);
        return (match && match[4].length)
          ? `https://player.vimeo.com/video/${match[4]}`
          : null;
      }
    }
    return '';
  }

  const classes = useMemo(() => {
    const isOutsideBlock = position === EBlockPosition.outsideStep && type !== EBlockType.sticky
    const marginClass = isOutsideBlock ? 'inner-video--no-margin-top' :
      (type !== EBlockType.simple ? '' : 'inner-video--margin-top-extra-big')

    return ['inner-video', marginClass].join(' ')
  }, [position, type]);

  if (!videoUrl) {
    return null
  }

  return (
    <>
      <div className={classes}>
        <iframe className="video inner-video__frame" width="1240" height="600"
                src={videoUrl} title={videoTitle}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                loading="lazy" allowFullScreen={false}/>
      </div>
      {embed?.caption && (<p className="video-description"><Text text={embed?.caption}/></p>)}
    </>
  )
}

export default EmbedMedia
