import React, {FC, memo, useMemo} from 'react';
import {IGridProps, ITranslation} from '@common/types';
import Link from 'next/link';
import {useTranslation} from '@common/hooks/useTranslation';
import {EBlockPosition, EBlockType} from '@redux/types';
import RichTextContent from '@common/components/RichTextContent';
import Text from '@common/components/common/Text';


const SimpleGrid: FC<IGridProps> = ({items, height, parent, type, position}) => {
  const t = useTranslation('components') as ITranslation['components'];

  const simpleGridClasses = useMemo(() => {
    const positionClasses = position !== EBlockPosition.insideStepDescription ? '' : ' inner-simple-cards--half';

    const marginClassByType = {
      [EBlockType.sticky]: ' inner-simple-cards--margin-small',
      [EBlockType.simple]: ' inner-simple-cards--margin-big',
      [EBlockType.grid]: '',
      [EBlockType.table]: '',
      [EBlockType.tools]: '',
    }[type || EBlockType.grid];

    const isOutsideBlock = type !== EBlockType.sticky && position === EBlockPosition.outsideStep;
    const marginClasses = isOutsideBlock ? ' inner-simple-cards--no-margin' : marginClassByType;
    const heightClasses = `inner-simple-cards--${height ?? 'height-125'}`;
    const additionalClasses = `inner-simple-cards--text-bottom inner-simple-cards--mobile-margin-middle`;

    return ['inner-simple-cards', positionClasses, marginClasses, heightClasses, additionalClasses].join(' ');
  }, [position, type, height]);

  return (
    <ul
      className={simpleGridClasses}>
      {items?.map((c, index) => (
        <li key={`card_${c.title}_${index}`} className="inner-simple-cards__item">
          <h3 className="inner-simple-cards__heading">
            <Text text={c.title}/></h3>
          {c?.text && (
            <RichTextContent classes={'inner-simple-cards__description'} content={c?.text} parent={parent || null}/>
          )}
          {c.url && (
            <p className="inner-simple-cards__description">
              {c.url && (
                <Link className="inner-simple-cards__link" href={c.url} aria-label={t.grid.card.linkAriaLabel}>
                  {c.urlTitle ?? c.url}
                </Link>
              )}
            </p>
          )}
        </li>
      ))}
    </ul>
  )
}

export default memo(SimpleGrid)
