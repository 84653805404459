import React, {FC, ReactNode} from 'react';
import {EBlockPosition, IStepComponent, TComponent} from '@redux/types';
import {useStepHeaderSettings} from '@common/hooks/useStepHeaderSettings';
import Text from '@common/components/common/Text';
import RichTextContent from '@common/components/RichTextContent';
import StepChildren from '@common/components/PageStep/StepChildren';

export const StepToolsHeader: FC<{ step: IStepComponent, stepChildren: TComponent[], children?: ReactNode }> = (
  {step, stepChildren, children}) => {

  const {emptyText, stepDescriptionInsideStepCount, headerClasses} = useStepHeaderSettings({
    step, stepChildren, children,
    classes: {
      mainClass: 'inner-tools__title',
      mobileNormalTitleClass: 'inner-tools__title--large'
    }
  })

  return (
    <>
      {(step?.title || step?.description || stepChildren.length || children) && (
        <div className={`inner-tools__content`}>
          {step?.title && (
            <h2 className={headerClasses}>
              <Text text={step.title}/>
            </h2>
          )}
          {!emptyText && (
            <div className="inner-tools__text">
              <RichTextContent content={step.description} parent={null}/>
            </div>
          )}
          {!!stepDescriptionInsideStepCount ? children : stepChildren?.map(stepChild => {
            if (stepChild?.placement?.position !== EBlockPosition.insideStepDescription) {
              return null;
            }
            return (
              <StepChildren key={stepChild?.id} components={[stepChild]} parentStep={step}/>
            )
          })}
        </div>
      )}
    </>
  )
}
