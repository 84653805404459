import React, {FC, memo, useMemo} from 'react';
import {IStepProps} from '@common/types';
import StepChildren from '@common/components/PageStep/StepChildren';
import Link from 'next/link';
import {EBlockPosition, EBlockType} from '@redux/types';
import {isNotEmptyRichContent} from '@common/functions';
import Text from '@common/components/common/Text';
import {
  StepGridHeader,
  StepSimpleBlockHeader,
  StepTableHeader,
  StepToolsHeader
} from '@common/components/PageStep/StepHeaders';


/**
 * Вывод шага. Шаг - это основная структурная единица страницы.
 * В шаге есть заголовок - для каждого типа шага отрисовывается по-разному, и тело шага.
 * Тело шага включает в себя массив компонентов, каждый из которых имеет свой тип позиционирования.
 * Так же, в шаг может быть передан сторонний jsx-компонент (через поле children).
 * Через children реализуется поддержка не типовых шагов (блока контактов, контент на главной и др.)
 * @param count - номер шага на странице
 * @param step - шаг
 * @param children - дополнительные блоки, которые должны быть отрисованы в контенте шага
 * @param classNames - дополнительные классы
 * @param stepChildren - контент шага (вычисляется заранее)
 * @param link - ссылка на ресурс
 * @param id - id шага (для скролла)
 * @param childrenPosition - позиции расположения контента шага
 * @constructor
 */
const Step: FC<IStepProps> = ({
                                count, step, children, classNames, stepChildren,
                                link, id, childrenPosition
                              }) => {

  const emptyNote = useMemo(() => !isNotEmptyRichContent(step?.note), [step])

  const isHideStep = step?.type === EBlockType.sticky && !step?.displayStepNumber && !step?.title && !step.name;
  const typeClassName = isHideStep ? EBlockType.grid : step?.type || EBlockType.grid;
  const asideClassName = (step?.type !== EBlockType.grid) || emptyNote ? '' : ' inner-grid--aside-left';

  const insideStepDescriptionChildren = childrenPosition === EBlockPosition.insideStepDescription ? children : null

  const headingCount = `${count < 10 ? '0' : ''}${count}`;

  if (step?.hidden) {
    return null
  }

  return (
    <>
      <section
        className={`${typeClassName} ${classNames?.join(' ') ?? ''}${asideClassName}`}
        id={step?.anchor ?? id ?? ''}
      >
        <div className="container">
          {step?.displayStepNumber && (
            <div className="section-heading">
              <span className="section-heading__count">{headingCount}</span>
              <h2 className="section-heading__title">
                <Text text={step.name}/>
              </h2>
              {link && (
                <>
                  <Link className="section-heading__link section-heading__link--desktop link" href={link.url}>
                    {link.title}
                  </Link>
                  <Link className="section-heading__link section-heading__link--mobile link" href={link.url}>
                    {link.titleMobile ?? link.title}
                  </Link>
                </>
              )}
            </div>
          )}

          {(!step?.type || step?.type === EBlockType.grid || step?.type === EBlockType.sticky) && (
            <StepGridHeader step={step} stepChildren={stepChildren || []}>
              {insideStepDescriptionChildren}
            </StepGridHeader>
          )}

          {(step?.type === EBlockType.simple) && (
            <StepSimpleBlockHeader step={step} stepChildren={stepChildren || []}>
              {insideStepDescriptionChildren}
            </StepSimpleBlockHeader>
          )}

          {step?.type === EBlockType.table && (
            <StepTableHeader step={step} stepChildren={stepChildren || []}>
              {insideStepDescriptionChildren}
            </StepTableHeader>
          )}

          {step?.type === EBlockType.tools && (
            <StepToolsHeader step={step} stepChildren={stepChildren || []}>
              {insideStepDescriptionChildren}
            </StepToolsHeader>
          )}

          {!stepChildren?.length ? (
            !childrenPosition || childrenPosition === EBlockPosition.insideStep ? children : null
          ) : (
            stepChildren?.map(stepChild => (
              (!stepChild?.placement?.position || stepChild?.placement?.position === EBlockPosition.insideStep) && (
                <StepChildren key={stepChild?.id} components={[stepChild]} parentStep={step}/>
              )
            ))
          )}
        </div>
      </section>

      {!stepChildren?.length ? (
        childrenPosition === EBlockPosition.outsideStep ? children : null
      ) : (
        stepChildren?.map(stepChild => (
          (stepChild?.placement?.position === EBlockPosition.outsideStep) && (
              <section className={stepChild?.placement?.type || EBlockType.grid} key={stepChild?.id || ''}>
                <div className="container">
                  <StepChildren components={[stepChild]} parentStep={step}/>
                </div>
              </section>
          )
        ))
      )}
    </>
  )
}

export default memo(Step)
